<template>
  <div :class="['wrap', !noAspectRatio && 'wistia-aspect-ratio']">
    <img
      loading="lazy"
      class="thumb_image"
      :class="(isPlaying && 'hide', playedOnce && 'hide')"
      :src="thumb"
      alt=""
      @click="playButtonPush"
    />
    <div
      ref="wistia_embed"
      :class="[
        `thumb wistia_embed`,
        popover && 'popover=true popoverAnimateThumbnail=true',
      ]"
      @click="playButtonPush"
    />
    <PlayButton
      v-show="!isPlaying"
      :is-playing="isPlaying"
      class="wistia-play"
      @click="playButtonPush"
    />
  </div>
</template>

<script>
  import { toRaw } from "vue"
  import loadExternalScript from "~/mixins/loadExternalScript"

  export default {
    mixins: [loadExternalScript],
    props: {
      wistiaId: {
        type: String,
        default: undefined,
      },
      popover: {
        type: Boolean,
        default: false,
      },
      noAspectRatio: {
        type: Boolean,
        default: false,
      },
      width: {
        type: String,
        default: "large",
        validator: (value) => {
          return ["small", "large"].includes(value)
        },
      },
    },

    setup(props) {
      const renderNow = ref(false)
      const { $router } = useNuxtApp()

      const { data } = useLazyAsyncData(
        `wistia-embed-${props.wistiaId}`,
        async () => {
          const res = await fetch(
            `https://fast.wistia.net/oembed?url=https://home.wistia.com/medias/${props.wistiaId}?embedType=async&videoWidth=640`,
          )
          const { thumbnail_url: image } = await res.json()
          return image
        },
      )

      onMounted(() => {
        renderNow.value =
          $router.currentRoute.value.query.wvideo === props.wistiaId
      })

      return {
        thumb: data,
        renderNow,
      }
    },
    data: function () {
      return {
        video: null,
        isPlaying: false,
        isBuilt: false,
        playedOnce: false,
        observer: null,
        embedInitConfig: null,
      }
    },

    mounted() {
      if (this.renderNow) {
        this.buildPlayer().then(() => {
          this.playButtonPush()
        })
      }
    },
    methods: {
      buildPlayer() {
        return new Promise((resolve) => {
          this.loadExternalScript(
            "https://fast.wistia.com/assets/external/E-v1.js",
            {
              defer: true,
            },
          )
          this.$refs.wistia_embed.classList.add(`wistia_async_${this.wistiaId}`)
          this.embedInitConfig = {
            id: this.wistiaId,
            onReady: (video) => {
              this.video = video

              if (this.video) {
                this.video.bind("play", () => {
                  this.isPlaying = true
                })
                this.video.bind("end", () => {
                  this.isPlaying = false
                })
                this.video.bind("pause", () => {
                  this.isPlaying = false
                })
              }
              this.isBuilt = true
              resolve()
            },
            options: {
              autoPlay: false,
              playsinline: false,
              playButton: false,
              fitStrategy: "cover",
              controlsVisibleOnLoad: false,
              settingsControl: true,
              playerColor: "00bfb3",
              plugin: {},
            },
          }

          window._wq = window._wq || []
          if (window._wq) {
            window._wq.push(this.embedInitConfig)
          }
        })
      },
      playButtonPush() {
        if (this.video) {
          const rawVideo = toRaw(this.video)
          if (this.isPlaying) {
            rawVideo.pause()
          } else {
            rawVideo.play()
          }

          this.playedOnce = true
        } else {
          this.buildPlayer().then(() => {
            const rawVideo = toRaw(this.video)
            rawVideo.play()
            this.playedOnce = true
          })
        }
      },
    },
  }
</script>

<style lang="scss">
  .wistia_popover_overlay {
    max-width: 100vw;
  }
</style>

<style lang="scss" scoped>
  .wistia-play {
    position: absolute;
    z-index: 15;
    right: 0;
    bottom: 0;
    margin: 6.25%;
    cursor: pointer;
  }
  .wistia_embed {
    background: $gray-100;
    iframe {
      height: 0px;
      opacity: 0;
    }
  }
  .wrap {
    position: relative;
    border-radius: $border-radius;
    overflow: hidden;
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
    &--spacing {
      margin-bottom: $space-m;
    }
  }
  .thumb {
    aspect-ratio: 16/9;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;

    border-radius: $border-radius;
    box-shadow: $shadow;
    overflow: hidden;
  }
  .thumb_image {
    z-index: 5;
    position: relative;
    width: 100%;
    height: 100%;
    transition: 350ms ease-out;
    pointer-events: none;
    &.hide {
      visibility: hidden;
      opacity: 0;
    }
  }
</style>
